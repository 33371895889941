import {Button, Col, Container, Form, Image, Row, Stack} from 'react-bootstrap'
import Navigation from './Navigation'
import {useEffect, useState} from 'react'
import phone from '../assets/phone.jpg'
import {useFilterData, useFilterTriggerActions} from 'src/providers/filter'

type Props = {
  activeRoute: string
  initialSearchTerm: string
  placeholderText: string
  itemTextSingular: string
  hasData: boolean
  onPerformSearch: (searchTerm: string) => void
  onSearchTermClear: () => void
  children: JSX.Element
}

type NoContentProps = {
  itemTextSingular: string
}

const NoContentMessage: React.FC<NoContentProps> = ({itemTextSingular}: NoContentProps) => {
  const {isFilterActive, setFilterControlsUI} = useFilterTriggerActions()
  const {clearFilterData} = useFilterData()
  return (
    <div
      style={{
        backgroundColor: '#55AAFF',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        borderRadius: 20,
        marginTop: 30,
        marginLeft: 10,
        marginRight: 10,
        textAlign: 'center',
      }}>
      {!isFilterActive() && (
        <p style={{color: 'black'}}>{`No ${itemTextSingular}s match your search term.`}</p>
      )}
      {isFilterActive() && (
        <>
          <p
            style={{
              color: 'black',
            }}>
            {`No ${itemTextSingular}s match your search term and filters. `}
          </p>
          <p>
            <Button size="sm" style={{marginRight: 5}} onClick={() => setFilterControlsUI('open')}>
              Modify
            </Button>{' '}
            or{' '}
            <Button size="sm" style={{marginLeft: 5, marginRight: 5}} onClick={clearFilterData}>
              clear
            </Button>{' '}
            filters.
          </p>
        </>
      )}
    </div>
  )
}

///
/// A page of content - whether tracks, artists or genres. Provides the navigation, and a search bar.
///
const ContentPage: React.FC<Props> = ({
  activeRoute,
  initialSearchTerm,
  placeholderText,
  itemTextSingular,
  hasData,
  onPerformSearch,
  onSearchTermClear,
  children,
}: Props) => {
  // This is the search term that is currently being edited.
  const [editTerm, setEditTerm] = useState(initialSearchTerm)

  // This is the actual searched-for search term.
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm)

  //
  // Called when the value in the search form changes.
  //
  const onSearchFormChange = (event: any) => {
    // Strip leading whitespace only.
    let trimmedSearchTerm = event.target.value.replace(/^\s+/g, '')
    setEditTerm(trimmedSearchTerm)
  }

  //
  // Called when asked to perform a search.
  //
  const onSearchSubmit = (event: any) => {
    event.preventDefault()

    // The edit term is now the search term
    setSearchTerm(editTerm)
  }

  useEffect(() => {
    if (searchTerm.length > 0) {
      // If the search term changes, then perform the search.
      onPerformSearch(searchTerm.trim())
    } else {
      // The search term has been cleared.
      onSearchTermClear()
    }
  }, [searchTerm, onPerformSearch, onSearchTermClear])

  // We only allow the search if enough text has been entered.
  const allowSearch = editTerm.length >= 3

  // And we only allow 'clear' if there's at least one character.
  const allowClear = editTerm.length > 0

  return (
    <Container
      style={{
        overflowY: 'scroll',
        paddingBottom: 40,
      }}>
      <Navigation activeRoute={activeRoute} />
      <form onSubmit={onSearchSubmit}>
        <Container>
          <Row>
            <Col xs={12} sm={8} md={9}>
              <Form.Group>
                <Form.Control
                  className="me-auto"
                  name="searchField"
                  placeholder={placeholderText}
                  value={editTerm}
                  onChange={onSearchFormChange}
                />
                <Form.Text style={{paddingLeft: 10}}>Enter at least 3 characters...</Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} sm={3}>
              <Container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  marginLeft: 10,
                  justifyContent: 'center',
                }}>
                <Button
                  disabled={allowSearch === false}
                  variant="secondary"
                  type="submit"
                  style={{maxWidth: 100, flex: 1, marginRight: 5}}>
                  Search!
                </Button>
                <Button
                  disabled={allowClear === false}
                  variant="outline-secondary"
                  style={{maxWidth: 100, flex: 1, marginLeft: 5}}
                  onClick={() => {
                    setEditTerm('')
                    setSearchTerm('')
                  }}>
                  Clear
                </Button>
              </Container>
            </Col>
          </Row>
        </Container>
      </form>

      <Container style={{height: 20}} />
      <Stack>
        {hasData ? children : <NoContentMessage itemTextSingular={itemTextSingular} />}
        <div style={{textAlign: 'center'}}>
          <Image fluid style={{marginTop: 50, objectFit: 'scale-down'}} src={phone} />
        </div>
      </Stack>
    </Container>
  )
}

export default ContentPage
