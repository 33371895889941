import reduceToCompare from './reduceToCompare'
import {TrackInfo} from './trackInfo'

//
// Info for the artist to display - including all the tracks..
//
export type ArtistInfo = {
  name: string
  tracks: TrackInfo[]
}

//
// This will sort the artist infos into display order.
//
export const artistInfoSort = (artistInfos: ArtistInfo[]) => {
  artistInfos.sort((a, b) => {
    const convertNameForSort = (name: string): string => {
      // Trim any of these off the start of the artist name (if present)
      const snips = new Set(['a', 'the']) // These must all be lowercase in order to match

      const trimmedName = name.trim()

      const firstSpaceIndex = trimmedName.indexOf(' ')
      if (firstSpaceIndex < 1) {
        return trimmedName
      }

      const firstStr = trimmedName.slice(0, firstSpaceIndex).trim().toLowerCase()

      console.log(
        `Got artist ${trimmedName}, firstSpaceIndex: ${firstSpaceIndex}, firstStr: "${firstStr}"`,
      )

      if (snips.has(firstStr)) {
        // This name DOES start with some text we want to snip, so return the rest .. if there IS a the-rest!
        const restStr = trimmedName.slice(firstSpaceIndex).trim()
        if (restStr.length >= 1) {
          return restStr
        }
      }

      return trimmedName
    }

    return reduceToCompare(convertNameForSort(a.name)).localeCompare(
      reduceToCompare(convertNameForSort(b.name)),
    )
  })
}
